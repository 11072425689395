import { Grid, Paper, Typography, IconButton, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface DroppedFilesGridProps {
  handleRemoveFile: (index: number) => void;
  files: File[];
}

const DroppedFilesGrid: React.FC<DroppedFilesGridProps> = ({
  handleRemoveFile,
  files,
}) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Grid container spacing={3}>
        {files.map((file, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                minWidth: "200px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minWidth: "200px",
                  flexWrap: "wrap", // add this line to allow items to wrap to the next line
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginRight: "10px",
                    textAlign: "left",
                    flex: 1,
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  {file.name}
                </Typography>

                <IconButton
                  color="error"
                  onClick={() => handleRemoveFile(index)}
                  sx={{
                    color: "#e74c3c",
                    "&:hover": {
                      backgroundColor: "#e74c3c",
                      color: "#fff",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DroppedFilesGrid;
