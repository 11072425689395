import { useState, useCallback } from "react";
import Modal from "react-modal";
import UploadProgressBar from "./UploadProgressBar";

interface LoadingDialogProps {
  uploadComplete: boolean;
  uploadProgress: number;
  isUploading: boolean;
  urls: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
  removeAllFile: (index: number) => void;
  isModalOpen: boolean;
  fileLength: number;
  uploadingFileIndex: number;
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({
  uploadComplete,
  uploadProgress,
  isUploading,
  urls,
  isModalOpen,
  setIsModalOpen,
  removeAllFile,
  fileLength,
  uploadingFileIndex,
}) => {
  const close = useCallback(() => {
    setIsModalOpen(false);
    removeAllFile(1);
  }, [setIsModalOpen, removeAllFile]);
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(urls);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Modal
      isOpen={(isUploading || uploadComplete) && isModalOpen}
      contentLabel="Upload Complete Dialog"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          color: "lightsteelblue",
          maxWidth: "400px",
          maxHeight: "300px",
          margin: "auto",
          padding: "10px",
          textAlign: "center",
        },
      }}
    >
      <UploadProgressBar uploadProgress={uploadProgress}></UploadProgressBar>
      <p>{`${uploadingFileIndex}/${fileLength}`}</p>
      <div
        style={{
          flexDirection: "row",
        }}
      >
        <h2>{uploadComplete ? "Upload Complete!" : "Uploading..."}</h2>
        {uploadComplete && (
          <div>
            {" "}
            <button
              onClick={copyToClipboard}
              style={{
                backgroundColor: "#ff7f50",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                margin: "5px",
                cursor: "pointer",
                transition: "0.3s",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#ff5722")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#ff7f50")
              }
            >
              {isCopied ? "✔️コピー済み" : "クリップボードにURLをコピー"}
            </button>
            <button
              onClick={close}
              style={{
                backgroundColor: "#9acd32",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                fontSize: "16px",
                margin: "5px",
                cursor: "pointer",
                transition: "0.3s",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#7cb342")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#9acd32")
              }
            >
              Close
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default LoadingDialog;
