interface UploadProgressBarProps {
  //   uploadComplete: boolean;
  uploadProgress: number;
}

const UploadProgressBar: React.FC<UploadProgressBarProps> = ({
  //   uploadComplete,
  uploadProgress,
}) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        style={{
          width: `100%`,
          height: "20px",
          flex: 1,
          border: "1px solid black",
          boxSizing: "border-box",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: `${uploadProgress}%`,

          backgroundColor: "blue",
          height: "20px",
          flex: 1,
        }}
      ></div>
      <p>{Number(uploadProgress.toFixed(2))}%</p>
    </div>
  );
};
export default UploadProgressBar;
