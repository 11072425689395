import { useState, useCallback } from "react";
import * as React from "react";
import FileDropArea from "./FileDropArea";
import { uploadToS3, configAWS, postToNotification } from "./Utils";
import UploadButton from "./uploadButton";
import Modal from "react-modal";
import ReactLoading from "react-loading";
import LoadingDialog from "./LoadingDialog";
import UploadedFilesTable from "./UploadedFilesTable";
interface UploadAreaProps {
  application: string;
  name: string;
}

Modal.setAppElement("#root");

const UploadArea: React.FC<UploadAreaProps> = ({ application, name }) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<
    { name: string; url: string }[]
  >([]);
  const [uploadingFileIndex, setUploadingFileIndex] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [urls, setUrls] = useState("");

  const [isDragOver, setIsDragOver] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const s3 = configAWS();

  const handleDrop = useCallback((files: File[]) => {
    setDroppedFiles((prevFiles) => [...prevFiles, ...files]);
  }, []);

  const handleUpload = useCallback(async () => {
    const addUrls = async (urls: string[]): Promise<string> => {
      var newUrl = urls.join("\n");
      setUrls((prevUrls) => prevUrls + "\n" + newUrl);
      return newUrl;
    };
    setUrls("");
    setUploading(true);
    setUploadComplete(false);
    setIsModalOpen(true);
    setUploadingFileIndex(0);
    var results: {
      name: string;
      url: string;
    }[] = [];

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      const result = await uploadToS3(file, application, s3, setUploadProgress);
      results.push(result);

      setUploadingFileIndex(i + 1);
    }
    try {
      setUploadedFiles((prevFiles) => [...prevFiles, ...results]);
      const newUrls: string = await addUrls(
        results.map((result) => result.url)
      );
      postToNotification(newUrls, name);

      setUploading(false);
      setUploadComplete(true);
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  }, [droppedFiles, s3, application, name, setUrls]);

  const handleRemoveFile = useCallback((index: number) => {
    setDroppedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  }, []);
  const removeAllFile = useCallback((index: number) => {
    setDroppedFiles([]);
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
        }}
      >
        <FileDropArea
          onFilesAdded={handleDrop}
          files={droppedFiles}
          isDragOver={isDragOver}
          setIsDragOver={setIsDragOver}
          handleRemoveFile={handleRemoveFile}
        />
        {uploading ? (
          <ReactLoading
            type="spin"
            color="black"
            height="20px"
            width="20px"
            className="mx-auto"
          />
        ) : (
          ""
        )}
        <UploadButton handleUpload={handleUpload}></UploadButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <UploadedFilesTable
            uploadedFiles={uploadedFiles}
          ></UploadedFilesTable>
          <LoadingDialog
            uploadComplete={uploadComplete}
            uploadProgress={uploadProgress}
            isUploading={uploading}
            urls={urls}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            uploadingFileIndex={uploadingFileIndex}
            fileLength={droppedFiles.length}
            removeAllFile={removeAllFile}
          ></LoadingDialog>
        </div>
      </div>
    </div>
  );
};

export default UploadArea;
