import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

interface SelectApplicationBoxProps {
  application: string;
  setApplication: React.Dispatch<React.SetStateAction<string>>;
}

const SelectApplicationBox: React.FC<SelectApplicationBoxProps> = ({
  application,
  setApplication,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (event: SelectChangeEvent<string>) => {
    setApplication(event.target.value);
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const updatedParams = { ...queryParams, app: application };
    const newSearch = qs.stringify(updatedParams);
    navigate(`${location.pathname}?${newSearch}`);
  }, [application, location.search, location.pathname, navigate]);

  return (
    <Box sx={{ minWidth: 200, width: 200, margin: 2 }}>
      <FormControl fullWidth>
        <InputLabel
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          アプリ
        </InputLabel>
        <Select value={application} onChange={handleChange} label="Application">
          <MenuItem value="melody">メロディ</MenuItem>
          <MenuItem value="gakufu-karaoke">楽譜カラオケ</MenuItem>
          <MenuItem value="taikogame">たいこでヒットソング</MenuItem>
          <MenuItem value="natsumelo">懐メロピアノ</MenuItem>
          <MenuItem value="others">その他</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectApplicationBox;
