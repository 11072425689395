interface UploadButtonProps {
  handleUpload: () => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ handleUpload }) => {
  return (
    <div>
      <button
        onClick={handleUpload}
        style={{
          width: "150px",
          height: "50px",
          backgroundColor: "#6c63ff", // Use a vibrant color
          marginTop: 10,
          marginLeft: 10,
          color: "white",
          borderRadius: "25px", // Round the corners of the button
          border: "none", // Remove the default button border
          cursor: "pointer", // Change the cursor when hovering over the button
          fontSize: "15px", // Increase the font size
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)", // Add a shadow to the button
          transition: "all 0.3s ease 0s", // Add a transition effect to the button
        }}
        onMouseOver={(e) => {
          // Change the color and scale of the button when the mouse hovers over it
          (e.target as HTMLButtonElement).style.backgroundColor = "#4834d4";
          (e.target as HTMLButtonElement).style.transform = "scale(1.1)";
        }}
        onMouseOut={(e) => {
          // Reset the color and scale of the button when the mouse is no longer hovering over it
          (e.target as HTMLButtonElement).style.backgroundColor = "#6c63ff";
          (e.target as HTMLButtonElement).style.transform = "scale(1)";
        }}
      >
        アップロード
      </button>
    </div>
  );
};
export default UploadButton;
