// FileDropArea.tsx
import { DragEvent, useCallback, useRef } from "react";
import { preventDefault } from "./Utils";
import DroppedFilesGrid from "./DroppedFilesGrid";

interface FileDropAreaProps {
  onFilesAdded: (files: File[]) => void;
  files: File[];
  isDragOver: boolean;
  setIsDragOver: (dragOver: boolean) => void;
  handleRemoveFile: (index: number) => void;
}

const FileDropArea: React.FC<FileDropAreaProps> = ({
  onFilesAdded,
  files,
  isDragOver,
  setIsDragOver,
  handleRemoveFile,
}) => {
  const handleDragEnter = useCallback(
    (e: DragEvent) => {
      preventDefault(e);
      setIsDragOver(true);
    },
    [setIsDragOver]
  );
  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);
      onFilesAdded(files);
    },
    [onFilesAdded]
  );

  const handleDragLeave = useCallback(
    (e: DragEvent) => {
      preventDefault(e);
      setIsDragOver(false);
    },
    [setIsDragOver]
  );

  const handleDragOver = useCallback((e: DragEvent) => {
    preventDefault(e);
    e.dataTransfer.dropEffect = "copy";
  }, []);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent) => {
      preventDefault(e);
      setIsDragOver(false);
      const files = Array.from(e.dataTransfer.files);
      onFilesAdded(files);
    },
    [onFilesAdded, setIsDragOver]
  );

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        alignContent: "center",
        width: "80%",
        minHeight: "250px",
        // height: "auto",
        height: 250 + files.length * 30,
        border: "2px dashed #6c63ff", // Use a vibrant color for the border
        backgroundColor: isDragOver ? "#c4c4c4" : "white", // Update background color
        borderRadius: "15px", // Round the corners of the div
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px auto",
        position: "relative",
        marginTop: 20,
        transition: "all 0.3s ease 0s", // Add a transition effect to the div
      }}
    >
      <p
        style={{
          color: isDragOver ? "#6c63ff" : "black", // Change text color based on isDragOver state
          fontSize: "16px", // Increase the font size
          marginBottom: 10, // Add margin to the text
        }}
      >
        {isDragOver
          ? "ファイルをここにドロップ"
          : "ファイルをここにドラッグ&ドロップ  または "}
      </p>
      <button
        onClick={handleFileInputClick}
        style={{
          backgroundColor: "#6c63ff", // Use a vibrant color for the button
          color: "white", // Make the text color white
          borderRadius: "15px", // Round the corners of the button
          border: "none", // Remove the default button border
          padding: "10px 20px", // Add padding to the button
          cursor: "pointer", // Change the cursor when hovering over the button
          fontSize: "15px", // Increase the font size
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)", // Add a shadow to the button
          transition: "all 0.3s ease 0s", // Add a transition effect to the button
        }}
        onMouseOver={(e) => {
          // Change the color and scale of the button when the mouse hovers over it
          (e.target as HTMLButtonElement).style.backgroundColor = "#4834d4";
          (e.target as HTMLButtonElement).style.transform = "scale(1.1)";
        }}
        onMouseOut={(e) => {
          // Reset the color and scale of the button when the mouse is no longer hovering over it
          (e.target as HTMLButtonElement).style.backgroundColor = "#6c63ff";
          (e.target as HTMLButtonElement).style.transform = "scale(1)";
        }}
      >
        ファイルを選択
      </button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        style={{ display: "none" }}
        multiple
      />
      <DroppedFilesGrid
        handleRemoveFile={handleRemoveFile}
        files={files}
      ></DroppedFilesGrid>
    </div>
  );
};

export default FileDropArea;
