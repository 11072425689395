import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBnjRZtM1E3ZL7SY8M3LvMyxAJRIDa_zpE",
  authDomain: "file-uploader-ab4c8.firebaseapp.com",
  projectId: "file-uploader-ab4c8",
  storageBucket: "file-uploader-ab4c8.appspot.com",
  messagingSenderId: "304126635351",
  appId: "1:304126635351:web:5ce750b0fbe46c7233e212",
  measurementId: "G-87PQEB25Z1",
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
