import AWS from "aws-sdk";
import { DragEvent } from "react";
import { format } from "date-fns";

function splitFileNameAndExtension(
  fileNameWithExtension: string
): [string, string] {
  const dotIndex = fileNameWithExtension.lastIndexOf(".");
  const fileName = fileNameWithExtension.slice(0, dotIndex);
  const extension = fileNameWithExtension.slice(dotIndex + 1);

  return [fileName, extension];
}

export const preventDefault = (e: DragEvent) => {
  e.preventDefault();
};

export const uploadToS3 = (
  file: File,
  directory: String,
  s3: AWS.S3,
  setUploadProgress: (progress: number) => void
) => {
  const now = new Date();
  const [fileName, ext] = splitFileNameAndExtension(file.name);
  const params = {
    Bucket: "genit.uploader",
    Key: `${directory}/${fileName}-${format(now, "yyyyMMddHHmmss")}.${ext}`,
    Body: file,
    ACL: "public-read",
  };

  const managedUpload = s3.upload(params);

  managedUpload.on("httpUploadProgress", function (evt) {
    const progress = (evt.loaded / evt.total) * 100;
    setUploadProgress(Number(progress.toFixed(2)));
    // console.log(Math.round((evt.loaded / evt.total) * 100));
  });

  return new Promise<{ name: string; url: string }>((resolve, reject) => {
    managedUpload.send((err: Error, data: AWS.S3.ManagedUpload.SendData) => {
      if (err) {
        console.log(err);
        reject(err);
        return;
      }
      resolve({ name: data.Key, url: data.Location });
    });
  });
};
export const postToNotification = async (url: string, name: string) => {
  const data = {
    data: {
      name: name,
      url: url,
    },
  };

  const functionUrl =
    "https://asia-northeast1-file-uploader-ab4c8.cloudfunctions.net/postNotification";

  try {
    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    console.log("Message sent successfully");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

export const configAWS = () => {
  AWS.config.update({
    region: "ap-northeast-1",
    accessKeyId: `${process.env.REACT_APP_ACCESS_KEY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY}`,
  });

  return new AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: "genit.uploader" },
  });
};

export const createTrelloCards = async (
  trelloBoardId: string,
  trelloListId: string,
  value: string
) => {
  const data = {
    data: {
      trelloBoardId: trelloBoardId,
      trelloListId: trelloListId,
      value: value,
    },
  };

  const functionUrl =
    "https://asia-northeast1-file-uploader-ab4c8.cloudfunctions.net/createTrelloCards";

  try {
    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    console.log("Message sent successfully");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};
