import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface UploadedFilesTableProps {
  //   uploadComplete: boolean;
  uploadedFiles: { name: string; url: string }[];
}

const UploadedFilesTable: React.FC<UploadedFilesTableProps> = ({
  uploadedFiles,
}) => {
  return (
    <div>
      {uploadedFiles.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>アップロード済みファイル</TableCell>
                <TableCell>URL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedFiles.map((file, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.url}
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
export default UploadedFilesTable;
