import React, { FC, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { createTrelloCards } from "../Utils";
import { db } from "../firebase"; // Firebase設定をインポート

interface Option {
  id: string;
  name: string;
  trelloBoardId: string;
  trelloDoingListId: string;
  trelloDoneListId: string;
}

const AboutPage: FC = () => {
  const [textAreaContent, setTextAreaContent] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const fetchOptions = async () => {
      const snapshot = await db.collection("chatworkRooms").get(); // あなたのコレクション名を指定します
      const options: Option[] = snapshot.docs.map(function (doc) {
        const data = doc.data();
        return {
          id: doc.id,
          name: `${data.userName}さん/${data.appName}`,
          trelloBoardId: data.trelloBoardId,
          trelloDoingListId: data.trelloDoingListId,
          trelloDoneListId: data.trelloDoneListId,
        };
      });

      setOptions(options);
    };

    fetchOptions();
  }, []);

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextAreaContent(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = () => {
    console.log(textAreaContent);
    console.log(selectedOption);
    const selected = options.find((option) => option.id === selectedOption);
    if (selected) {
      console.log(selected);
      createTrelloCards(
        selected.trelloBoardId,
        selected.trelloDoingListId,
        textAreaContent
      );
    }

    setOpenDialog(false);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <h1>Trelloに依頼中のカードを作ります</h1>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">名前/アプリ名</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOption}
          label="名前/アプリ名"
          onChange={handleSelectChange}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <p>改行区切りでカードのタイトルを入力してください</p>
      <TextField
        multiline
        variant="outlined"
        rows={4}
        value={textAreaContent}
        onChange={handleTextAreaChange}
        sx={{ width: "100%", mt: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        sx={{ mt: 2 }}
      >
        Submit
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"確認"}</DialogTitle>
        <DialogContent>
          <h3>
            {options.find((option) => option.id === selectedOption)?.name}
          </h3>
          <DialogContentText>
            <div style={{ whiteSpace: "pre-line" }}>{textAreaContent}</div>
          </DialogContentText>
        </DialogContent>
        <br />
        <DialogActions>
          <Button onClick={handleCloseDialog}>キャンセル</Button>
          <Button onClick={handleConfirmDialog} autoFocus>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AboutPage;
