import React, { useState, useEffect, FC } from "react";
import { Toolbar } from "@mui/material";
import UploadArea from "./UploadArea";
import logo from "./genit.png";
import SelectApplicationBox from "./SelectApplicationBox";
import { TextField, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import qs from "query-string";

const TopPage: FC = () => {
  const query = qs.parse(window.location.search) as { [key: string]: string };
  const [application, setApplication] = React.useState<string>(query.app || "");
  const [inputVal, setInputVal] = useState("");
  const [cookies, setCookie] = useCookies(["name"]);

  const handlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value);
  };

  const handleBlur = (e: React.FormEvent) => {
    e.preventDefault();
    setCookie("name", inputVal);
  };
  useEffect(() => {
    setInputVal(cookies.name || "");
  }, [cookies.name]);

  return (
    <div>
      <Toolbar style={{ display: "flex", justifyContent: "start" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ height: "50px", marginRight: "10px" }}
        />
        <Typography variant="h5" color="inherit">
          ファイルアップローダー
        </Typography>
        <SelectApplicationBox
          application={application}
          setApplication={setApplication}
        />{" "}
        <TextField
          name="name"
          label="ユーザー名を入力"
          value={inputVal}
          onChange={handlerChange}
          onBlur={handleBlur}
        />
      </Toolbar>

      <UploadArea application={application} name={cookies.name} />
    </div>
  );
};

export default TopPage;
